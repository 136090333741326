<template>
  <div>
    <b-button
      variant="primary"
      class="btn btn-icon btn-light btn-hover-primary mr-3"
      v-on:click="$emit('refreshClicked', $event)"
    >
      <span class="svg-icon svg-icon-2x svg-icon-primary">
        <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
      </span>
    </b-button>


  </div>
</template>

<script>
export default {
  name: "EmployeeAttendancePageControl",
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {

  },
};
</script>

<style scoped></style>
