<template>
  <div class="d-flex mx-3">
    <b-form-group label="Year" class="mr-3">
      <b-form-select
        v-model="yearSelection"
        :options="years"
        class="form-control-solid"
      >
      </b-form-select>
    </b-form-group>
    <div class="my-auto ml-3 mr-auto" v-if="!noSearch">
      <div
        class="btn btn-icon btn-light btn-hover-primary mr-3"
        @click="searchByYear"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <inline-svg src="/media/svg/icons/General/Search.svg" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "AppFieldYearSelector",
  props: {
    yearField: {
      type: Number,
      default: dayjs().year(),
    },
    noSearch: {
      type: Boolean,
      default: false,
    },
    dateValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      yearSelection: this.yearField,
    };
  },
  methods: {
    /**
     * Search button clicked event
     */
    searchByYear() {
      this.defineDateFilter();
      this.$emit("clickedSearch", {
        year: this.yearSelection,
      });
    },

    /**
     * Set the date filter
     */
    defineDateFilter() {
      const objectSupport = require("dayjs/plugin/objectSupport");
      dayjs.extend(objectSupport);

      const searchable = dayjs({
        year: this.yearSelection,
      });
      console.log(searchable.format());
    },
  },
  watch: {
    dateValue(newVal) {
      console.log(newVal);

      this.defineDateFilter();
      this.$emit("fetched-date", {
        year: this.yearSelection,
      });
    },
  },
  computed: {
    /**
     * Compute year
     *
     * @returns {*[]}
     */
    years() {
      const now = dayjs();
      let years = [];
      for (let i = 0; i < 5; i++) {
        let sYear = now.add(i, "year");
        years.push({
          value: sYear.format("YYYY"),
          text: sYear.format("YYYY"),
        });
      }

      return years;
    },
  },
};
</script>

<style scoped></style>
